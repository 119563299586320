import fonts from './modules/fonts';
import header from './modules/header';
import lightbox from './modules/lightbox';
import eyecatcher from './modules/eyecatcher';
import objectFitImages from 'object-fit-images';

// import any polyfills and other libs you want to use in older browsers here
import 'svgxuse';

// lets check if we have a modern browser, and then, enhance!
// Edge, Firefox, Chrome, Opera as well as IE10+, iOS7+ and Android 4.4+
if ('visibilityState' in document) {
  // remove the no-js class
  document.documentElement.classList.remove('no-js');

  // load all modules
  fonts();
  header();
  lightbox();
  eyecatcher();
  objectFitImages('.banner-image > img');
}
