export default () => {

  const hashCode = function createHashCode (s) {
    return s.split('').reduce((a, b) => {
      const c = ((a < 5) - a) + b.charCodeAt(0);

      return c && c;
    }, 0);
  };

  /**
     * Set cookie
     *
     * @param string name
     * @param string value
     * @param int days
     * @param string path
     * @see http://www.quirksmode.org/js/cookies.html
     */
  const createCookie = function createCookie (name, value, days) {
    let expires = '';
    const path = '';

    if (days) {
      const date = new Date();

      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = `; expires=${date.toGMTString()}`;
    }
    document.cookie = `${name}=${value}${expires}; path=${path}`;
  };

  /**
   * Read cookie
   * @param string name
   * @returns {*}
   * @see http://www.quirksmode.org/js/cookies.html
   */
  const readCookie = function readCookie (name) {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];

      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  const eyecatcherWrapper = document.querySelector('.eyecatcher-wrap');
  const eyecatcher = document.querySelector('.eyecatcher');
  const close = document.querySelector('.eyecatcher .close');
  const eyecatcherContent = eyecatcher.innerHTML;
  const cookieName = `fuchsbau_${hashCode(eyecatcherContent)}`;

  if (readCookie(cookieName)) {
    eyecatcherWrapper.remove();
  }

  close.addEventListener('click', (e) => {
    e.preventDefault();
    createCookie(cookieName, 1, 30);
    eyecatcherWrapper.remove();
  });

  // const header = document.querySelector('.main-header');

  // toggleButton.addEventListener('click', () => {
  //   const menuIsVisible = header.className.indexOf('main-header--menu-open') !== -1;

  //   if (menuIsVisible) {
  //     // hide
  //     header.className = 'main-header';
  //   } else {
  //     // show
  //     header.className += ' main-header--menu-open';
  //   }
  // });

};
