export default () => {

  const toggleButton = document.querySelector('.button--menu');
  const header = document.querySelector('.main-header');

  toggleButton.addEventListener('click', () => {
    const menuIsVisible = header.className.indexOf('main-header--menu-open') !== -1;

    if (menuIsVisible) {
      // hide
      header.className = 'main-header';
    } else {
      // show
      header.className += ' main-header--menu-open';
    }
  });

};
